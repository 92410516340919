

import UMUM from         '../../library/umum'
import FETCHING from         '../../library/fetching'

export default {
  data() {
    return {
      list_data : [],
      list_data_detile : [],

      page_first: 1,
      page_last: 0,
      cari_value: "",
      cek_load_data : true,


      form : {
        NPWP: "",
        TMT_PNS: "",
        agama: "",
        alamat: "",
        email: "",
        gelar_belakang: "",
        gelar_depan: "",
        gol: "",
        hadir: "",
        id: "",
        izin: "",
        jabatan: "",
        jenis_kelamin: "",
        jmlHariKerja: "",
        kontak: "",
        metode_absen: "",
        nama: "",
        nip: "",
        nm_jabatan: "",
        no_karpeg: "",
        pendidikan_ahir_jurusan: "",
        persentaseHadir: "",
        persentaseIzin: "",
        persentaseTanpaKeterangan: "",
        status_keluarga: "",
        tanpaKeterangan: "",
        tempat_lahir: "",

      },

      data_absen : {
        id_absen : "",
        dd : "",
        mm : "",
        yy : "",
        full : "",
        ket : "",
        ket_libur : "",
        keterangan : "",
        jeniskategori_uraian : "",
        jamDatang : "",
        jamPulang : "",
        status : "",
        nip : '',
      },

      filterku : {
        unit_kerja_id : '',
        date : '',
        bulan : '',
        tahun : '',
        waktuFirst: '',
        waktuLast : '',
        nip : '',
      },

      uraian : {
        unit_kerja : '',
      },

      UMUM :UMUM,
      FETCHING : FETCHING,

      check_all : true,


      mdl_printing: false,
      mdl_detile : false,
      mdl_detile_edit : false,

      simpan1: false,
      model :'',
      inputSelect : [
        {id : '1', nama : 'kiken', alamat : 'beringin'},
        {id : '2',nama : 'Alung', alamat : 'Anawai'},
      ]
    }
  },
  methods: {

    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_presensi_lapCustom + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              date : this.filterku.date,
              bulan : this.filterku.bulan,
              tahun : this.filterku.tahun,
              unit_kerja_id : this.filterku.unit_kerja_id,
              data_ke: this.page_first,
              cari_value: this.cari_value,

              waktuFirst: this.filterku.waktuFirst,
              waktuLast: this.filterku.waktuLast,

          })
      })
          .then(res => res.json())
          .then(res_data => {
            this.$store.commit("hideLoading");
            this.list_data = res_data
              // console.log(res_data);
      });
    },
    

    selectData : function(data){

        this.form.NPWP = data.NPWP ;
        this.form.TMT_PNS = data.TMT_PNS ;
        this.form.agama = data.agama ;
        this.form.alamat = data.alamat ;
        this.form.email = data.email ;
        this.form.gelar_belakang = data.gelar_belakang ;
        this.form.gelar_depan = data.gelar_depan ;
        this.form.gol = data.gol ;
        this.form.hadir = data.hadir ;
        this.form.id = data.id ;
        this.form.izin = data.izin ;
        this.form.jabatan = data.jabatan ;
        this.form.jenis_kelamin = data.jenis_kelamin ;
        this.form.jmlHariKerja = data.jmlHariKerja ;
        this.form.kontak = data.kontak ;
        this.form.metode_absen = data.metode_absen ;
        this.form.nama = data.nama ;
        this.form.nip = data.nip ;
        this.form.nm_jabatan = data.nm_jabatan ;
        this.form.no_karpeg = data.no_karpeg ;
        this.form.pendidikan_ahir_jurusan = data.pendidikan_ahir_jurusan ;
        this.form.persentaseHadir = data.persentaseHadir ;
        this.form.persentaseIzin = data.persentaseIzin ;
        this.form.persentaseTanpaKeterangan = data.persentaseTanpaKeterangan ;
        this.form.status_keluarga = data.status_keluarga ;
        this.form.tanpaKeterangan = data.tanpaKeterangan ;
        this.form.tempat_lahir = data.tempat_lahir ;



        this.data_absen.nip = data.nip;


        this.getViewList();


    },


    getViewList : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_presensi_lapCustom_v2 + "list", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              nip : this.form.nip,
              waktuFirst: this.filterku.waktuFirst,
              waktuLast: this.filterku.waktuLast,

          })
      })
          .then(res => res.json())
          .then(res_data => {
            this.$store.commit("hideLoading");
            // console.log(res_data)

            var data = [];

            res_data.forEach(element => {

              element.nip = this.form.nip

              if (element.status == 2 && element.ket != "LIBUR") {
                element.inject = true
                element.jamDatang = this.randomNilai(null, '07', 30, 1)
                element.jamPulang = this.randomNilai(null, '15', 59, 30)
                
              } else {
                element.inject = false
              }


              data.push(element)
              
            });

            this.check_all = false

            console.log(data)


            this.list_data_detile = data
            //   console.log(list_data_detile);
      });
    },

    check_uncheck : function(){


      var res_data = this.list_data_detile
      var data = []


      if (this.check_all == true) {
  
        res_data.forEach(element => {


          element.jamDatang = this.randomNilai(null, '07', 30, 1)
          element.jamPulang = this.randomNilai(null, '15', 59, 30)
  
          if (element.ket == "LIBUR") {
            element.inject = false
          } else {
            element.inject = true
          }
  
          data.push(element)
  
        });


      } else {


        res_data.forEach(element => {
          element.inject = false
          data.push(element)
        });
        
      }


      // console.log(data)


      this.list_data_detile = data




    },

    editDataDetile : function(){

      fetch(this.$store.state.url.URL_presensi_lapCustom_v2 + "Update", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.data_absen)
      }).then(res_data => {
          this.Notify('Sukses Memperbaharui Data', 'primary', 'check_circle_outline');
          this.getViewList();
          this.mdl_detile_edit = false;
      });

    },

    editDataDetileAll : function(){
      // console.log(this.list_data_detile)

      fetch(this.$store.state.url.URL_presensi_lapCustom_v2 + "UpdateAll", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.list_data_detile)
      }).then(res_data => {
          this.Notify('Sukses Memperbaharui Data', 'primary', 'check_circle_outline');
          this.getViewList();
          this.mdl_detile_edit = false;
      });




    },

    selectdata_absen : function(data){
        this.data_absen.id_absen = data.id_absen;
        this.data_absen.dd = data.dd;
        this.data_absen.mm = data.mm;
        this.data_absen.yy = data.yy;
        this.data_absen.full = data.full;
        this.data_absen.ket = data.ket;
        this.data_absen.ket_libur = data.ket_libur;
        this.data_absen.keterangan = data.keterangan;
        this.data_absen.jeniskategori_uraian = data.jeniskategori_uraian;

        // this.data_absen.jamDatang = data.jamDatang;
        // this.data_absen.jamPulang = data.jamPulang;
        this.data_absen.jamDatang = this.randomNilai(data.jamDatang, '07', 30, 1)
        this.data_absen.jamPulang = this.randomNilai(data.jamPulang, '15', 59, 30)

        

        this.data_absen.status = data.status
    },

    clearLibur : function(data){

      fetch(this.$store.state.url.URL_presensi_lapCustom_v2 + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(data)
      }).then(res_data => {
          this.Notify('Sukses Memperbaharui Data', 'primary', 'check_circle_outline');
          this.getViewList();
          this.mdl_detile_edit = false;
      });

    },


    searchByDate(){
      var waktuFirst = this.filterku.waktuFirst
      var waktuLast = this.filterku.waktuLast


      var date = waktuFirst.split('/')
      this.filterku.tahun = date[0];
      this.filterku.bulan = parseInt(date[1]);
      this.filterku.date = date[2];

      this.getView()
    },


    filterUnitKerja : function (val, update, abort) {
        update(() => {
          if (val === '') {}
            else {
            FETCHING.postUnitKerjaAuto(val)
            }
        })
    },


    simulateProgress (number) {
      // we set loading state
      this[`simpan${number}`] = true
      // simulate a delay
      setTimeout(() => {
        // we're done, we reset loading state
        this[`simpan${number}`] = false
      }, 3000)
    },


    async showChannel(val){
      // console.log(val)
      var data = await FETCHING.postUnitKerjaId('', val)
      this.uraian.unit_kerja = data[0].unit_kerja
    },




    print () {
      // Pass the element id here
      this.$htmlToPaper('printMe');
    },


    pembulatan : function(data){
      if (data == null || data == undefined || data == '') {
        return 0
      } else {
        // console.log('ASSSSSSSSSSSSSS');
        var angka = data;
        var hasil = angka.toFixed(1);
        return hasil
        
      }
    },

    randomNilai : function(value, jamx, menit_limit, menit_start){

        // var nilai_menit = Math.floor(Math.random() * menit_limit) + menit_start;
        var nilai_menit = Math.floor(Math.random() * (menit_limit - menit_start)) + menit_start;
        var menit = UMUM.addZeroFirst(nilai_menit);

        // var jamx = '07:';
        var jam = jamx+':'+menit;

        if (value == null || value == undefined || value == "") {
          return jam
        } else {
          return value
        }

    },

    Notify : function(message, positive, icon){
      this.$q.notify({
        message: message,
        color: positive,
        icon: icon,
        position : 'top',
        timeout: 500,
      })
    },







  },


  mounted() {

    const d = new Date();

    // this.filterku.waktuFirst = d.getFullYear()+'/'+(d.getMonth()+1)+'/'+d.getDate()
    // this.filterku.waktuLast = d.getFullYear()+'/'+(d.getMonth()+1)+'/'+d.getDate()


    var tahun = d.getFullYear();
    var bulan = d.getMonth()+1;
    var date = d.getDate()

    var bulanModiv = ''

    this.filterku.waktuFirst = d.getFullYear()+'/'+"01"+'/'+"01"
    this.filterku.waktuLast = tahun+'/'+ UMUM.addZeroFirst(bulan) +'/'+UMUM.addZeroFirst(date)

    console.log(this.filterku.waktuFirst)
    
    this.filterku.tahun = tahun;
    this.filterku.bulan = bulan;
    this.filterku.date = date


    console.log(this.filterku.bulan)

    // console.log(tahun+"-"+bulan+"-"+date)




    this.filterku.unit_kerja_id = this.$store.state.unit_kerja
    this.FETCHING.postUnitKerjaAuto('', this.filterku.unit_kerja_id)

    this.showChannel(this.$store.state.unit_kerja)

    this.getView()






  },
}
